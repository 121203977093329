import {
  add,
  addDays,
  addMonths,
  addYears,
  differenceInDays,
  startOfYear,
} from 'date-fns'
import { Subscription, SubscriptionInterval } from '~common/components/product'
import {
  SUBSCRIPTIONS_BIWEEKLY,
  SUBSCRIPTIONS_MONTHLY,
  SUBSCRIPTIONS_PIF,
  SUBSCRIPTIONS_WEEKLY,
} from '~common/components/product/constants'
import {
  OrderLine,
  FacetValue,
  OrderLineFragmentFragment,
} from '~common/generated/admin-graphql'

export const addInterval = (
  startDate: Date,
  interval: SubscriptionInterval,
  intervalCount: number,
) => {
  switch (interval) {
    case 'week':
      return addDays(startDate, intervalCount * 7)
    case 'month':
      return addMonths(startDate, intervalCount)
    case 'year':
      return addYears(startDate, intervalCount)
    default:
      throw new Error(`Unsupported duration interval: ${interval}`)
  }
}

const getDaysInInterval = (
  interval: SubscriptionInterval,
  intervalCount: number,
): number => {
  const startDate = startOfYear(new Date())
  startDate.setHours(12, 0, 0, 0)
  const endDate = addInterval(startDate, interval, intervalCount)

  return differenceInDays(endDate, startDate)
}

const getOneDayBillingCycleEndDate = (
  startDate: Date,
  interval: SubscriptionInterval,
  intervalCount: number,
): Date => {
  const daysInInterval = getDaysInInterval(interval, intervalCount)
  const endDate = add(startDate, {
    days: daysInInterval,
    hours: 6, // add a bit of extra buffer to avoid rounding errors
  })
  return endDate
}

type SubscriptionTupleType = [] | [Subscription, Subscription] | [Subscription]

export const getSubscriptionTuple = (
  s: SubscriptionTupleType | undefined,
): SubscriptionTupleType => {
  if (!s) {
    console.log('No subscriptions available')
    return []
  }
  const recurringFee = s[0]

  if (!recurringFee) {
    return []
  }

  const registrationFee = s[1]

  if (registrationFee) {
    return [recurringFee, registrationFee]
  } else {
    return [recurringFee]
  }
}

export const extractSubscriptionsFromBreakdown = (
  line: OrderLineFragmentFragment,
): SubscriptionTupleType => {
  if (!line.subscriptionDetails) {
    console.log('Missing subscriptionDetails')
    return []
  }
  const {
    amount,
    amountDueNow,
    discountedDownpayment,
    renewalDate,
    pricingInterval: interval,
    pricingIntervalCount: intervalCount,
    intervalStartDate,
    durationInterval,
    durationIntervalCount,
    billingInterval,
    autoRenew,
  } = line.subscriptionDetails
  const {
    id: productVariantId,

    // product,
    name: variantName,
  } = line.productVariant
  // const { name: productName } = product ?? {}
  const subscriptions: Subscription[] = []
  if (
    durationInterval === interval &&
    durationIntervalCount === intervalCount &&
    discountedDownpayment <= 0
  ) {
    subscriptions.push({
      name: `Full Payment Renewal - ${line.id}`,
      variantId: productVariantId,
      priceIncludesTax: false, // it doesn't really matter right now
      amountDueNow,
      recurring: {
        amount,
        interval: durationInterval as unknown as SubscriptionInterval,
        intervalCount: durationIntervalCount,
        startDate: renewalDate,
        ...(autoRenew === false
          ? {
              endDate: getOneDayBillingCycleEndDate(
                renewalDate,
                durationInterval as unknown as SubscriptionInterval,
                durationIntervalCount,
              ),
            }
          : {}),
      },
    })
  } else {
    subscriptions.push({
      name: `${billingInterval}ly Installment - ${variantName}`,
      variantId: productVariantId,
      priceIncludesTax: false,
      amountDueNow: amountDueNow,
      recurring: {
        amount: amount,
        interval: interval as unknown as SubscriptionInterval,
        intervalCount: intervalCount,
        startDate: intervalStartDate,
        ...(autoRenew === false
          ? {
              endDate: getOneDayBillingCycleEndDate(
                intervalStartDate,
                interval as unknown as SubscriptionInterval,
                intervalCount,
              ),
            }
          : {}),
      },
    })

    if (discountedDownpayment > 0) {
      subscriptions.push({
        name: `Initial Fee - ${variantName}`,
        variantId: productVariantId,
        priceIncludesTax: false,
        amountDueNow: 0,
        recurring: {
          amount: autoRenew ? Math.max(0, discountedDownpayment) : 0,
          interval: durationInterval as unknown as SubscriptionInterval,
          intervalCount: durationIntervalCount,
          startDate: renewalDate,
          ...(autoRenew === false
            ? {
                endDate: getOneDayBillingCycleEndDate(
                  renewalDate,
                  durationInterval as unknown as SubscriptionInterval,
                  durationIntervalCount,
                ),
              }
            : {}),
        },
      } as Subscription)
    }
  }
  return subscriptions as SubscriptionTupleType
}

/**
 * returns empty array if no subscriptions exist, or an array with either 1 or 2 subscriptions
 * the first subscription is the subscription with recurring value,
 * and the second subscription is the optional recurring registration fee
 * @param line
 */
export const extractSubscriptions = (
  line: OrderLine,
): SubscriptionTupleType => {
  const subscriptions =
    line.acceptBlueSubscriptions ||
    line.stripeSubscriptions ||
    line.subscriptions
  // we assume bi-weekly will be represented as intervalCount: 2, interval: week
  return getSubscriptionTuple(
    subscriptions as unknown as SubscriptionTupleType | undefined,
  )
}

export const convertSubscriptionFacetToInterval = (
  subscriptions: FacetValue[],
): { interval: SubscriptionInterval; intervalCount: number }[] => {
  return subscriptions?.map((s) => {
    switch (s.code) {
      case SUBSCRIPTIONS_BIWEEKLY:
        return { interval: SubscriptionInterval.week, intervalCount: 2 }
      case SUBSCRIPTIONS_WEEKLY:
        return { interval: SubscriptionInterval.week, intervalCount: 1 }

      case SUBSCRIPTIONS_PIF:
        return { interval: SubscriptionInterval.year, intervalCount: 1 }
      default:
        return { interval: SubscriptionInterval.month, intervalCount: 1 }
    }
  })
}
