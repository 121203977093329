import { OrderLine } from '~common/generated/admin-graphql'
import { userDateFormat } from './dateHelper'
import { format, isBefore } from 'date-fns'

export const getLineDateRange = (line: OrderLine): string | undefined => {
  const { productVariant, subscriptionDetails } = line
  if (!subscriptionDetails) {
    return
  }
  const { startDate, endDate } = subscriptionDetails
  const { product, customFields: productVariantCustomFields } =
    productVariant ?? {}

  if ((!startDate || isBefore(startDate, new Date())) && !endDate) {
    return
  }

  return `week of ${format(new Date(startDate), 'MMM d, yyyy')}`

  // return `${format(new Date(startDate), 'MMM d')}${endDate ? `-${format(new Date(endDate), 'MMM d, yyyy')}` : ''}`
}
